var VARIABLES = (function(){
	var variables = {
		"WEBSITE": "sneeuwkettingenhuren",
		"PRODUCTEN": {
			"sneeuwkettingen": {
				"url": "/sneeuwkettingen",
				"title": {
					"singular": "Sneeuwkettingenset",
					"plural": "Sneeuwkettingen",
				},
				"label": {
					"singular": "Sneeuwkettingenset",
					"plural": "Sneeuwkettingen",
				},
				"tekstlabel": {
					"singular": "sneeuwkettingenset",
					"plural": "sneeuwkettingen",
				},
				"article": "de",
				"huurtarief": "huurtarief"
			},
			"sneeuwsokken": {
				"url": "/sneeuwsokken",
				"title": {
					"singular": "Sneeuwsokkenset",
					"plural": "Sneeuwsokken",
				},
				"label": {
					"singular": "Sneeuwsokkenset",
					"plural": "Sneeuwsokken",
				},
				"tekstlabel": {
					"singular": "sneeuwsokkenset",
					"plural": "sneeuwsokken",
				},
				"article": "de",
				"huurtarief": "huurtarief"
			}
		},
		"HOOFDPRODUCT": "sneeuwkettingen",
		"PRODUCTPARAMETERS": {
			"sneeuwkettingen": "wagentype",
			"sneeuwsokken": false
		},
		"LOCATIES": {
			"aanmeldpunt": {
				"label": "Aanmeldpunt",
				"lat": 51.032295,
				"long": 5.376493,
				"omschrijving": "BOSTHON Sport – Aanmeldpunt",
				"straat_nummer": "Grote Baan 61",
				"postcode": "3530",
				"plaats": "Houthalen-Helchteren"
			},
			"montagepunt": {
				"label": "Montagepunt",
				"lat": 51.033644,
				"long": 5.377519,
				"omschrijving": "BOSTHON Sport – Montagepunt",
				"straat_nummer": "Grote Baan 148",
				"postcode": "3530",
				"plaats": "Houthalen-Helchteren"
			}
		}
	};

	return{
		get: function(index){
			if(variables[index] === undefined){
				throw new Error('Variabele \'' + index + '\' ontbreekt.');
			}
			else{
				return variables[index];
			}
		}
	};
})();